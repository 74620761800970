.testimonal .items {
  padding: 30px;
  transition: 0.5s;
}

.testimonal .img {
  position: relative;
}
.testimonal img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.testimonal i {
  position: absolute;
  bottom: 0;
  left: 60px;
  background-color: #1eb2a6;
  color: #fff;
}
.testimonal .name {
  margin: 25px;
}
.testimonal .name h2 {
  font-size: 20px;
  margin-bottom: 5px;
}
.testimonal .name span {
  color: #1eb2a6;
}
.testimonal p {
  margin-top: 20px;
  color: grey;
  transition: 0.5s;
}
.testimonal .items:hover {
  background-color: #1eb2a6;
  color: #fff;
  cursor: pointer;
}
.testimonal .items:hover span,
.testimonal .items:hover p {
  color: #fff;
}
.testimonal .items:hover i {
  background-color: #fff;
  color: #1eb2a6;
}

/*Habout*/
.control-btn .next,
.control-btn .prev {
  position: absolute;
  top: 40%;
  width: 50px;
  height: 50px;
  line-height: 60px;
  background: #0f3460;
  border-radius: 50%;
  transform: 0.5s;
  z-index: 5;
}
.control-btn .prev {
  left: -20px;
}
.control-btn .next {
  /* right: -60px; */
}
.control-btn .prev i,
.control-btn .next i {
  font-size: 20px;
  transition: 0.5s;
  color: #fff;
}
.control-btn .prev:hover,
.control-btn .next:hover {
  background:  #e94560;
  color: white;
  cursor: pointer;
}
  /*Habout*/
