


ffooter {
  bottom: 20px !important;
  margin-top: auto;
  text-align: center;
  position: relative;
  font-weight: bold;
}

/* .link {
  text-decoration: none;
  font-weight: bold;
  color: #FF5252;
} */

/* FRAME STARTS */
.array {
  padding: 1rem;
  display: flex;
  min-height: 80vh;
  align-items: flex-start;
}
.cell {
  flex: 0.5;
  margin: 1px;
  display: flex;
  resize: horizontal;
  position: relative;
  align-items: flex-start;
  background-color: #D6D6D6;
  transition: all .4s ease-in;
}
.cell.done {
  background-color: #9CEC5B;
  border-color: #9CEC5B;
  transition: all .4s ease-out;
}
.cell.current {
  border-color: #6184D8;
  background-color: #6184D8;
  transition: all .4s ease-out;
}
/* FRAME ENDS */

/* NAVBAR STARTS */
.navbar {
  top: 0;
  overflow: hidden;
  font-size: 16px;
  min-height: 60px;
  text-align: center;
  background-color: 
  #1eb2a6;
}
.navbar .options {
  margin: 10px;
  text-align: center;
}
.navbar a {
  outline: none;
  cursor: pointer;
  color: #ccd6f6;
  padding: 13px 13px;
  text-decoration: none;
}
.navbar > a:hover {
  color: #64ffda;
}
.navbar #menu {
  margin-top: 15px;
  width: 150px;
  height: 25px;
  outline: none;
}
.navbar > .icon {
  display: none;
}
#start {
  margin: 10px;
}
#random {
  margin-top: 5px;
}
.buttonVisualizer {
  width: 100px;
  padding: 5px;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  background-color: #222;
  transition: 0.2s ease-in;
  border: 2px solid #ccd6f6;
}
.buttonVisualizer:hover {
  color: #222;
  background-color: #ccd6f6;
}
