 @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://stackpath.bootstrapcdn.com/bootstrap/5.0.0-alpha2/css/bootstrap.min.css");
@import url("http://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css");
@import url("https://kit.fontawesome.com/8b2d7de728.js"); 

/*--------head------------*/
.head {
  padding: 20px 0;
  color: #fff;
}

.sociall {
  padding-left: 100px;
  margin-left: 100px;

}


.logoimm img{
  width: 70%;
  height: auto;
  padding: 0 10px;
  margin-left: 0px;
  padding-left: 0px;
  padding-bottom: 10px;
}

.logo h1 {
  font-size: 35px;
  font-weight: 900;
}
.logo h6 {
  color: #139c90;
}

.logo h6:hover{
  color: #3dbbb0;
}
.head .iconn {
  margin-left: 10px;
}


/* For Cart and Checkout */


.middel_right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}


.header_black .wishlist_btn {
  margin-left: 18px;
}
.header_black .wishlist_btn a {
  width: 45px;
  height: 45px;
  border-radius: 3px;
  text-align: center;
  line-height: 45px;
  font-size: 20px;
  display: block;
  border: 1px solid #1eb2a6;
  color: #ffffff;
}
.header_black .wishlist_btn a:hover {
  color: #1eb2a6;
}
.cart_link {
  margin-left: 25px;
  position: relative;
}
.cart_link span.cart_quantity {
  position: absolute;
  left: -11px;
  width: 20px;
  line-height: 20px;
  text-align: center;
  background: #31abd7;
  color: #ffffff;
  border-radius: 50%;
  top: 12px;
}
.cart_link>a {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  color: #1eb2a6;
  border: 1px solid #ebebeb;
  padding: 11px 10px 13px 18px;
  line-height: 15px;
  border-radius: 3px;
  display: block;
}
.cart_link>a:hover {
  color: #1eb2a6;
}
.cart_link>a i.ion-android-cart {
  font-size: 19px;
  margin-right: 10px;
  border-right: 1px solid #ebebeb;
  position: relative;
  top: 3px;
  padding-right: 10px;
}
.cart_link>a i.ion-chevron-down {
  font-size: 17px;
  margin-left: 3px;
}
.header_black .cart_link span.cart_quantity {
  background: #1eb2a6
  ;
}
.header_black .cart_link>a {
  color: #ffffff;
  border: 1px solid #1eb2a6;
}
.header_black .cart_link>a:hover {
  color: #1eb2a6;
}
.header_black .cart_link>a i.ion-android-cart {
  border-right: 1px solid #1eb2a6;
}
.mini_cart {
  position: fixed;
  min-width: 355px;
  height: 100%;
  padding: 18px 20px 33px;
  background: #ffffff;
  z-index: 999;
  right: 0;
  top: 0;
  transition: 0.5s;
  transform: translateX(100%);
}
.mini_cart.active {
  transform: translateX(0);
  transition: 0.5s;
}
.header_black .mini_cart {
  background: #1faca1;
  border: 1px solid #177e75;
}
.cart_close {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 10px;
}
.header_black .cart_close {
  border-bottom: 1px solid #1eb2a6;
}
.cart_text h3 {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 0;
  color: #ffffff;
  padding-left: 100px;
}
.mini_cart i:hover{
  color: #035047;
}
.mini_cart_close>a {
  font-size: 25px;
  color: #ffffff;
}
.mini_cart_close>a:hover {
  color: #1eb2a6;
}
.cart_item {
  padding: 20px 0;
  border-bottom: 1px solid #07796c;
  display: flex;
  overflow: hidden;
}
.cart_img {
  width: 100px;
  margin-right: 10px;
}
.cart_info {
  width: 58%;
}
.cart_info a {
  font-weight: 400;
  color: #ffffff;
  text-transform: capitalize;
  font-size: 14px;
}
.cart_info a:hover {
  color: #035047;
}
.cart_info span {
  display: block;
}
.cart_info span.price_cart {
  color: #e7e5e5;
  font-weight: 500;
}
.header_black .cart_info span.quantity {
  color: #05665b;
}
.cart_remove {
  width: 10%;
  text-align: right;
}
.cart_remove a {
  color: #eaeaea;
  font-size: 18px;
}
.cart_remove a:hover {
  color: #035047;
}
.cart_total {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}
.cart_total span {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}
.mini_cart_footer {
  padding: 0 25px 25px;
  background: #1eb2a6;
}
.cart_button a {
  text-transform: uppercase;
  border-radius: 5px;
  font-weight: 400;
  color: #ffffff;
  background: #0d5f58;
  display: block;
  text-align: center;
  line-height: 20px;
  margin-bottom: 0;
  padding: 13px 0 11px;
}
.cart_button a:hover {
  background: #128779;;
}
.cart_button.view_cart {
  padding-bottom: 15px;
}
.cart_button a.active {
  background: #ffffff;
  color: #128779;
}
.cart_button a.active:hover {
  background: #bdbbbb;
  color: #000000;
  font-weight: 600;
}
/*--------head------------*/
/*--------header------------*/
header {
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 30px;
}
header .nav {
  display: flex !important;
}
header li {
  margin-right: 40px;
}
header ul {
  padding: 30px 20px;
}

.head .Cart{
    margin-right: 40px;
    padding: 10px 10px;
}
header ul li a {
  color: #fff;
  font-weight: 500;
  transition: 0.5s;
}
header ul li a:hover {
  color: #1eb2a6;
}
.start {
  background-color: #1eb2a6;
  padding: 30px 70px;
  color: #fff;
  clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
}
.toggle {
  display: none;
}
/*--------header------------*/
@media screen and (max-width: 768px) {
  .start {
    clip-path: none;
  }
  header {
    margin: 0;
    background-color: #1eb2a6;
    position: relative;
  }
  header ul.flexSB {
    display: none;
  }
  header ul li {
    margin-bottom: 20px;
  }


  .toggle {
    display: block;
    background: none;
    color: #fff;
    font-size: 30px;
    position: absolute;
    right: 0;
    top: -20px;
  }
  .mobile-nav {
    position: absolute;
    top: 7vh;
    left: 0;
    width: 100%;
    background-color: #1eb2a6;
  }
}
