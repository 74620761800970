.Successcontainer
{
    background:#f2f2f2;
    margin-bottom: 200px;
}

.Successpayment
{
    border:1px solid #f2f2f2;
    height:280px;
    border-radius:20px;
    background:#fff;
}
.Successpayment_header
{
   background:rgba(255,102,0,1);
   padding:20px;
   border-radius:20px 20px 0px 0px;
   
}

.check
{
   margin:0px auto;
   width:50px;
   height:50px;
   border-radius:100%;
   background:#fff;
   text-align:center;
}

.check i
{
   vertical-align:middle;
   line-height:50px;
   font-size:30px;
}

.Successcontent 
{
    text-align:center;
}

.Successcontent  h1
{
    font-size:25px;
    padding-top:25px;
}

.Successcontent a
{
    width:200px;
    height:35px;
    color:#fff;
    border-radius:30px;
    padding:5px 10px;
    background:rgba(255,102,0,1);
    transition:all ease-in-out 0.3s;
}

.Successcontent a:hover
{
    text-decoration:none;
    background:#000;
}