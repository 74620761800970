@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://stackpath.bootstrapcdn.com/bootstrap/5.0.0-alpha2/css/bootstrap.min.css");
@import url("http://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css");
@import url("https://kit.fontawesome.com/8b2d7de728.js");

.coursesCard {
  padding: 30px 0px; 
  margin-right: 30px;
  margin-left: 30px;
}

.coursesCard .items {
  background-color: #fff;
  padding-left: 30px;
  padding-right: 30px;
  width: 500px;
  height: auto;
  text-align: center;
}
.coursesCard .img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #1eb2a6;
  padding: 15px;
}
.coursesCard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.coursesCard .text {
  margin-left: 25px;
  text-align: left;
}
.coursesCard .text h1 {
  font-size: 10px;
  font-weight: 900;
  line-height: 45px;

}
.coursesCard .rate {
  margin: 20px 0;
  color: #1eb2a6;
}
.coursesCard .rate i {
  font-size: 13px;
  margin-right: 5px;
}
.coursesCard .details .dimg img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
}
.coursesCard .details .box {
  display: flex;
  align-items: center;
  color: grey;
}
.coursesCard .details span {
  color: #1eb2a6;
  font-weight: 500;
  font-size: 14px;
}
.coursesCard .price {
  margin: 30px 0;
  background-color: #f8f8f8;
  padding: 10px;
}
.coursesCard h3 {
  font-weight: 500;
  color: #1eb2a6;
}

/* Course Real */
/* Course */

#course{
  padding: 8vw 8vw 0vw 8vw;
  text-align: center;
}

#course .course-box{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 1rem;
  margin-top: 50px;
}

#course .courses{
  text-align: start;
  background-color: #F9F9FF;
  height: 100%;
  position: relative;
}

#course .courses img{
  width: 100%;
  height: 60%;
  background-size: cover;
  background-position: center;
}

#course .courses .details{
  padding: 15px 15px 0px 15px;
}

.details h4{
  font-weight: bold;
}

.details i{
  color: #FDC938;
  font-size: .9rem;
}

#course .courses .cost{
  background-color: #524A4E;
  color: #fff;
  line-height: 70px;
  width: 70px;
  height: 70px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  right: 15px;
  bottom: 100px;
}

.seller{
  background-color: #D3ECA7;
  color: black;
  width: 90px;
  height: 25px;
  text-align: center;
  font-weight: bold;
  margin-top: 5px;
}

/*-------------online---------*/
.online {
  text-align: center;
}
.online .box {
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
  background-color: #fff;
  padding: 30px 20px;
  transition: 0.5s;
}
.online .img {
  width: 80px;
  height: 80px;
  margin: auto;
  position: relative;
}
.online img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.online h1 {
  font-weight: 500;
  font-size: 20px;
  margin: 20px 0;
  line-height: 30px;
}
.online span {
  background-color: #f8f8f8;
  padding: 5px 20px;
  font-weight: 500;
  font-size: 15px;
  color: #1eb2a6;
  border-radius: 5px;
}
.online .box .show {
  opacity: 0;
}
.online .box:hover {
  background-color: #1eb2a6;
  border-radius: 5px;
  cursor: pointer;
}
.online .box:hover .show {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
}
.online .box:hover h1 {
  color: #fff;
}
/*-------------online---------*/
@media screen and (max-width: 768px) {
}
