/* dynamicWrapper classes */

.course1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .wrapper {
    display: inline-flex;
  }
  .wrapper .static-txt {
    font-size: 60px;
    font-weight: 400;
    margin-top: 15px;
  }
  .wrapper .dynamic-txts {
    margin-left: 15px;
    line-height: 90px;
    height: 90px;
    overflow: hidden;
    padding-bottom: 10px;
  }
  .dynamic-txts li {
    color: #FC6D6D;
    list-style: none;
    font-size: 60px;
    font-weight: 500;
    position: relative;
    top: 0;
    animation: slide 12s steps(4) infinite;
  }
  @keyframes slide {
    100% {
      top: -360px;
    }
  }
  .dynamic-txts li span {
    position: relative;
  }
  .dynamic-txts li span::after {
    content: "";
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #ffffff;
    border-left: 2px solid #FC6D6D;
    animation: typing 3s steps(19) infinite;
  }
  @keyframes typing {
    40%, 60% {
      left: calc(100% + 30px);
    }
    100% {
      left: 0;
    }
  }