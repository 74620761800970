.roww {
    display: -ms-flexbox; 
    display: flex;
    -ms-flex-wrap: wrap; 
    flex-wrap: wrap;
    margin: 0 -16px;
    padding: 10px 30px;
  }
  
  .coll-25 {
    -ms-flex: 25%; 
    flex: 25%;
  }
  
  .coll-50 {
    -ms-flex: 50%; 
    flex: 50%;
  }
  
  .coll-75 {
    -ms-flex: 75%; 
    flex: 75%;
  }
  
  .coll-25,
  .coll-50,
  .coll-75 {
    padding: 0 16px;
  }
  
  .checkoutcontainer {
    background-color: #f2f2f2;
    padding: 5px 20px 15px 20px;
    border: 1px solid lightgrey;
    border-radius: 3px;
  }
  
  input[type=text] {
    width: 100%;
    margin-bottom: 20px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  label {
    margin-bottom: 10px;
    display: block;
  }
  
  .icon-container{
    margin-bottom: 20px;
    padding-left: 5px;
    font-size: 24px;
  }

  .icon-container i{
    padding-left: 13px;
  }
  
  .btnn {
    background-color: #343a40;
    color: white;
    padding: 12px;
    margin: 30px 30px 20px;
    border: none;
    width: 40%;
    border-radius: 3px;
    cursor: pointer;
    font-size: 17px;
  }
  
  .btnn:hover {
    background-color: #6c757d;
  }
  
  span.price {
    float: right;
    color: grey;
  }
  
  
  @media (max-width: 800px) {
    .roww {
      flex-direction: column-reverse;
    }
    .coll-25 {
      margin-bottom: 20px;
    }
  }
