.head-container {
    margin-top: 100px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
   }
   
   .quote {
    width: 600px;
    transform: translateY(-50px);
    background-color: #F7F7F7;
    padding: 30px;
   }
   
   .quote h5 {
    margin-top: 20px;
    color: #0009;
    line-height: 20px;
   }
   
   .quote p {
    font-size: 50px;
    color: #2e2e2e;
   }
   
   .quote .play {
    margin-top: 20px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
   }
   
   .quote .play img {
    width: 45px;
    cursor: pointer;
    filter: drop-shadow(0 0 10px #0002);
   }
   
   .quote .play span a {
    margin-left: 10px;
    font-size: 20px;
    font-weight: 900;
    color: rgba(0, 0, 0, 0.8);
    cursor: pointer;
   }
   
   .svg-image img {
    width: 700px;
    filter: drop-shadow(0 20px 10px rgba(0, 0, 0, 0.2));
   }