@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css?family=Montserrat:500&display=swap');
@import url('https://fonts.googleapis.com/css?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://kit.fontawesome.com/8b2d7de728.js");

/* @import url("https://stackpath.bootstrapcdn.com/bootstrap/5.0.0-alpha2/css/bootstrap.min.css"); */
@import url("http://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css");

@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";


.carousel{
    z-index: -1;
  }
  .carousel-item{
    height: 100vh;
    min-height: 300px;
    background: no-repeat scroll center scroll;
    -webkit-background-size: cover;
    background-size: cover;
  }
  .carousel-item::before{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0.7;
  }
  .carousel-caption{
    bottom: 180px;
    padding-left: 100px;
    padding-right: 100px;
  }
  .carousel-caption h5{
    font-size: 40px;
    font-weight: 700;
  }
  .carousel-caption p{
    font-size: 18px;
    top: 2rem;
  }
  .carousel-item .slider{
    margin-top: 30px;
  }
  .carousel-item .slider button{
    background-color: #139c90;
    color: white;
    border-radius: 0;
    padding: 1.5rem 2rem;
    font-size: 1.25rem;
    margin-right: 15px;
    text-align: center;
    justify-content: space-around;
    width: 200px;
    border-radius: 10px;
  }
  .carousel-item .slider .btn2{
    color: #111010;
    background-color: white;
  }